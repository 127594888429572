// TaskList.js
import React from 'react';
import TaskItem from './TaskItem';

const TaskList = ({ tasks, updatePriority, deleteTask }) => {
  return (
    <div className="task-list p-4 bg-gray-50 rounded-lg shadow-md mb-8">
      <h2 className="category-title text-xl font-bold mb-4">Tasks</h2>
      {tasks.length > 0 ? (
        tasks.map((task) => (
          <TaskItem 
            key={task.id} 
            task={task} 
            updatePriority={updatePriority}
            deleteTask={deleteTask}  // Passing deleteTask to each TaskItem
          />
        ))
      ) : (
        <p className="text-gray-500">No tasks available. Add a task to get started!</p>
      )}
    </div>
  );
};

export default TaskList;

import React, { useState, useEffect } from 'react';
import { CgCalendarToday } from "react-icons/cg";
import TaskInput from './TaskInput';
import TaskList from './TaskList';
import './App.css';

const App = () => {
  const getFromLocalStorage = (key, defaultValue = []) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  };

  const [tasks, setTasks] = useState(() => getFromLocalStorage('tasks'));
  const [doNow, setDoNow] = useState(() => getFromLocalStorage('doNow'));
  const [schedule, setSchedule] = useState(() => getFromLocalStorage('schedule'));
  const [delegate, setDelegate] = useState(() => getFromLocalStorage('delegate'));
  const [archive, setArchive] = useState(() => getFromLocalStorage('archive'));
  const [completedTasks, setCompletedTasks] = useState(() => getFromLocalStorage('completedTasks'));
  const [showCompleted, setShowCompleted] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
    localStorage.setItem('doNow', JSON.stringify(doNow));
    localStorage.setItem('schedule', JSON.stringify(schedule));
    localStorage.setItem('delegate', JSON.stringify(delegate));
    localStorage.setItem('archive', JSON.stringify(archive));
    localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
  }, [tasks, doNow, schedule, delegate, archive, completedTasks]);

  const addTask = (task) => {
    const newTask = {
      id: Date.now(),
      text: task,
      priority: { important: null, urgent: null },
    };
    setTasks((prevTasks) => [...prevTasks, newTask]);
  };

  const isTaskInList = (list, taskId) => list.some((task) => task.id === taskId);

  const categorizeTask = (task) => {
    const { important, urgent } = task.priority;

    if (important === true && urgent === true && !isTaskInList(doNow, task.id)) {
      setDoNow((prevDoNow) => [...prevDoNow, task]);
    } else if (important === true && urgent === false && !isTaskInList(schedule, task.id)) {
      setSchedule((prevSchedule) => [...prevSchedule, task]);
    } else if (important === false && urgent === true && !isTaskInList(delegate, task.id)) {
      setDelegate((prevDelegate) => [...prevDelegate, task]);
    } else if (important === false && urgent === false && !isTaskInList(archive, task.id)) {
      setArchive((prevArchive) => [...prevArchive, task]);
    }
  };

  const updatePriority = (id, type, value) => {
    setTasks((prevTasks) => {
      return prevTasks.reduce((acc, task) => {
        if (task.id === id) {
          const updatedTask = { ...task, priority: { ...task.priority, [type]: value } };
          const { important, urgent } = updatedTask.priority;

          if (important !== null && urgent !== null) {
            categorizeTask(updatedTask);
            return acc;
          }
          return [...acc, updatedTask];
        }
        return [...acc, task];
      }, []);
    });
  };

  const markAsComplete = (id, category) => {
    let updatedCategory = [];
    let completedTask = null;

    switch (category) {
      case 'doNow':
        updatedCategory = doNow.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setDoNow(updatedCategory);
        break;
      case 'schedule':
        updatedCategory = schedule.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setSchedule(updatedCategory);
        break;
      case 'delegate':
        updatedCategory = delegate.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setDelegate(updatedCategory);
        break;
      case 'archive':
        updatedCategory = archive.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setArchive(updatedCategory);
        break;
      default:
        return;
    }

    if (completedTask) {
      setCompletedTasks((prevCompletedTasks) => [...prevCompletedTasks, completedTask]);
    }
  };

 const deleteTask = (id) => {
    // Filter out the deleted task from all lists
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id));
    setDoNow((prevDoNow) => prevDoNow.filter((task) => task.id !== id));
    setSchedule((prevSchedule) => prevSchedule.filter((task) => task.id !== id));
    setDelegate((prevDelegate) => prevDelegate.filter((task) => task.id !== id));
    setArchive((prevArchive) => prevArchive.filter((task) => task.id !== id));
    setCompletedTasks((prevCompletedTasks) => prevCompletedTasks.filter((task) => task.id !== id));
  };

  const handleRightClickDelete = (e, id) => {
    e.preventDefault(); // Prevent the default right-click context menu
    if (window.confirm("Are you sure you want to delete this task?")) {
      deleteTask(id); // Call the delete function for the specific task
    }
  };

  return (
  <div className={`app px-4 ${darkMode ? 'dark' : ''}`}>
      {/* <button 
        className="toggle-dark-mode p-2 mb-4 bg-gray-800 text-white rounded-md"
        onClick={() => setDarkMode(!darkMode)}
      >
        Toggle Dark Mode
      </button> */}
      <div className="main-content flex flex-col md:flex-row md:justify-between gap-8">
        <div className="task-input-section md:w-1/2">
          <TaskInput addTask={addTask} />
          <TaskList
            tasks={tasks.filter((task) => task !== null)}
            updatePriority={updatePriority}
            deleteTask={deleteTask}
          />
        </div>

        <div className={`prioritized-lists md:w-1/2 p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
          <h2 className="category-title text-xl font-bold mb-4">Do Now</h2>
          {doNow.length > 0 ? (
            doNow.map((task) => (
              <div key={`doNow-${task.id}`} className={`flex items-center mb-4 p-4 shadow-md rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`} onContextMenu={(e) => handleRightClickDelete(e, task.id)}>
                <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'doNow')} />
                <span>{task.text}</span>
              </div>
            ))
          ) : (
            <p className="empty-state">No tasks available. Add a task to get started!</p>
          )}

          <h2 className="category-title text-xl font-bold mb-4 mt-8">Schedule</h2>
          {schedule.length > 0 ? (
            schedule.map((task) => (
              <div key={`schedule-${task.id}`} className={`flex items-center mb-4 p-4 shadow-md rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`} onContextMenu={(e) => handleRightClickDelete(e, task.id)}>
                <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'schedule')} />
                <span className="flex-grow ml-4">{task.text}</span>
                <button
                  className="ml-auto text-blue-500 hover:text-blue-700"
                  onClick={() => {
                    const eventUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(task.text)}`;
                    window.open(eventUrl, '_blank');
                  }}
                >
                  <CgCalendarToday />
                </button>
              </div>
            ))
          ) : (
            <p className="empty-state">No tasks available. Add a task to get started!</p>
          )}

          <h2 className="category-title text-xl font-bold mb-4 mt-8">Delegate</h2>
          {delegate.length > 0 ? (
            delegate.map((task) => (
              <div key={`delegate-${task.id}`} className={`flex items-center mb-4 p-4 shadow-md rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`} onContextMenu={(e) => handleRightClickDelete(e, task.id)}>
                <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'delegate')} />
                <span>{task.text}</span>
              </div>
            ))
          ) : (
            <p className="empty-state">No tasks available. Add a task to get started!</p>
          )}

          <h2 className="category-title text-xl font-bold mb-4 mt-8">Nope</h2>
          {archive.length > 0 ? (
            archive.map((task) => (
              <div key={`archive-${task.id}`} className={`flex items-center mb-4 p-4 shadow-md rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`} onContextMenu={(e) => handleRightClickDelete(e, task.id)}>
                <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'archive')} />
                <span>{task.text}</span>
              </div>
            ))
          ) : (
            <p className="empty-state">No tasks available. Add a task to get started!</p>
          )}

          <div className="mt-8">
            <div
              className="cursor-pointer category-title text-xl font-bold mb-4 mt-8"
              onClick={() => setShowCompleted((prevShowCompleted) => !prevShowCompleted)}
            >
              {showCompleted ? '▼ Completed Tasks' : '▶ Completed Tasks'}
            </div>
            {showCompleted && (
              <div>
                {completedTasks.length > 0 ? (
                  completedTasks.map((task) => (
                    <div key={`completed-${task.id}`} className={`mb-4 p-4 shadow-md rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`} onContextMenu={(e) => handleRightClickDelete(e, task.id)}>
                      <span>{task.text} (Completed)</span>
                    </div>
                  ))
                ) : (
                  <p className="empty-state">No completed tasks yet.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
